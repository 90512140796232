@import url(https://fonts.googleapis.com/css?family=Major+Mono+Display|Noto+Sans+KR);
@import url(https://fonts.googleapis.com/css?family=VT323);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-size: 18px;
  font-family: 'Noto Sans KR', sans-serif; }

/* Override Bulma  */
a {
  color: #000 !important; }

#Teaser {
  font-family: 'VT323', monospace;
  font-size: 20px;
  line-height: 1.1;
  white-space: pre; }
  #Teaser .blinker {
    -webkit-animation: blinker 0.5s linear infinite;
            animation: blinker 0.5s linear infinite; }

@-webkit-keyframes blinker {
  50% {
    opacity: 0; } }

@keyframes blinker {
  50% {
    opacity: 0; } }
  #Teaser input {
    border: 0;
    font-family: 'VT323', monospace;
    font-size: 20px;
    color: #fff;
    background: #0a0a0a;
    display: hidden; }
    #Teaser input:focus {
      outline: none !important;
      border: 0; }

.full-window-height {
  height: 100vh; }

.app-wrapper {
  width: 100%; }

