@import url('https://fonts.googleapis.com/css?family=Major+Mono+Display|Noto+Sans+KR');
@import url('https://fonts.googleapis.com/css?family=VT323');

body {
  font-size: 18px;
  font-family: 'Noto Sans KR', sans-serif;
}

/* Override Bulma  */
a {
  color: #000 !important;
}

#Teaser {
  font-family: 'VT323', monospace;
  font-size: 20px;
  line-height: 1.1;
  white-space: pre;
  .blinker {
    animation: blinker 0.5s linear infinite;
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  input {
    border: 0;
    font-family: 'VT323', monospace;
    font-size: 20px;
    color: #fff;
    background: #0a0a0a;
    display: hidden;
    &:focus {
      outline: none !important;
      border: 0;
    }
  }
}

.full-window-height {
  height: 100vh;
}

.app-wrapper {
  width: 100%;
}
